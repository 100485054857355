var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AuthBase", [
    _c(
      "div",
      { staticStyle: { "text-align": "left" } },
      [
        _c("KButton", {
          staticStyle: { "margin-bottom": "16px" },
          attrs: { appearance: "basic-link", "data-test": "goback" },
          on: { click: _vm.goBack },
          scopedSlots: _vm._u([
            {
              key: "icon",
              fn: function () {
                return [
                  _c("KIcon", {
                    style: {
                      fill: _vm.$themeTokens.primary,
                      height: "1.125em",
                      width: "1.125em",
                      position: "relative",
                      marginRight: "8px",
                      top: "2px",
                    },
                    attrs: { icon: "back" },
                  }),
                  _vm._v(_vm._s(_vm.coreString("goBackAction")) + "\n      "),
                ]
              },
              proxy: true,
            },
          ]),
        }),
        _c("p", [
          _vm._v(
            _vm._s(
              _vm.$tr("needToMakeNewPasswordLabel", { user: _vm.username })
            )
          ),
        ]),
        _c("PasswordTextbox", {
          ref: "createPassword",
          attrs: {
            autofocus: true,
            disabled: _vm.busy,
            value: _vm.password,
            isValid: _vm.passwordIsValid,
            shouldValidate: _vm.busy,
          },
          on: {
            "update:value": function ($event) {
              _vm.password = $event
            },
            "update:isValid": function ($event) {
              _vm.passwordIsValid = $event
            },
            "update:is-valid": function ($event) {
              _vm.passwordIsValid = $event
            },
            submitNewPassword: _vm.updatePassword,
          },
        }),
        _c("KButton", {
          staticStyle: {
            width: "100%",
            margin: "24px auto 0",
            display: "block",
          },
          attrs: {
            appearance: "raised-button",
            primary: true,
            text: _vm.coreString("continueAction"),
            disabled: _vm.busy,
            "data-test": "submit",
          },
          on: { click: _vm.updatePassword },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }