var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.users, function (username) {
      return _c(
        "KButton",
        {
          key: username,
          staticClass: "listed-user",
          staticStyle: {
            width: "100%",
            "text-align": "left",
            "margin-left": "0px",
          },
          attrs: { disabled: _vm.busy },
          on: {
            click: function ($event) {
              return _vm.$emit("userSelected", username)
            },
          },
        },
        [
          _c("KIcon", {
            staticStyle: { "margin-right": "8px" },
            attrs: { icon: "person" },
          }),
          _vm._v("\n\n    " + _vm._s(username) + "\n\n  "),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }