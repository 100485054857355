var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AuthBase", { attrs: { hideCreateAccount: true } }, [
    _c("div", { staticClass: "auth-select" }, [
      _c(
        "div",
        [
          _c("div", { staticClass: "label" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.userString("signInPrompt")) + "\n      "
            ),
          ]),
          _c("KRouterLink", {
            staticStyle: { width: "100%" },
            attrs: {
              text: _vm.coreString("signInLabel"),
              to: _vm.signInRoute,
              appearance: "raised-button",
              primary: true,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "sign-up-prompt" },
        [
          _c("div", { staticClass: "label" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.$tr("newUserPrompt")) + "\n      "
            ),
          ]),
          _c("KRouterLink", {
            staticStyle: { width: "100%" },
            attrs: {
              text: _vm.userString("createAccountAction"),
              to: _vm.signUpRoute,
              primary: false,
              appearance: "raised-button",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }