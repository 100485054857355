var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "signup-page" },
    [
      _c("KPageContainer", { staticClass: "narrow-container" }, [
        _c(
          "form",
          {
            ref: "form",
            staticClass: "signup-form",
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.handleSubmit.apply(null, arguments)
              },
            },
          },
          [
            _c("h1", [_vm._v(_vm._s(_vm.$tr("createAccount")))]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.atFirstStep,
                    expression: "atFirstStep",
                  },
                ],
              },
              [
                _c("FullNameTextbox", {
                  ref: "fullNameTextbox",
                  attrs: {
                    autocomplete: "name",
                    value: _vm.name,
                    isValid: _vm.nameValid,
                    autofocus: true,
                    shouldValidate: _vm.formSubmitted,
                    disabled: _vm.busy,
                  },
                  on: {
                    "update:value": function ($event) {
                      _vm.name = $event
                    },
                    "update:isValid": function ($event) {
                      _vm.nameValid = $event
                    },
                    "update:is-valid": function ($event) {
                      _vm.nameValid = $event
                    },
                  },
                }),
                _c("UsernameTextbox", {
                  ref: "usernameTextbox",
                  attrs: {
                    autocomplete: "username",
                    value: _vm.username,
                    isValid: _vm.usernameValid,
                    shouldValidate: _vm.formSubmitted,
                    errors: _vm.caughtErrors,
                    disabled: _vm.busy,
                  },
                  on: {
                    "update:value": function ($event) {
                      _vm.username = $event
                    },
                    "update:isValid": function ($event) {
                      _vm.usernameValid = $event
                    },
                    "update:is-valid": function ($event) {
                      _vm.usernameValid = $event
                    },
                    "update:errors": function ($event) {
                      _vm.caughtErrors = $event
                    },
                  },
                }),
                _vm.showPasswordInput
                  ? _c("PasswordTextbox", {
                      ref: "passwordTextbox",
                      attrs: {
                        autocomplete: "new-password",
                        value: _vm.password,
                        isValid: _vm.passwordValid,
                        shouldValidate: _vm.formSubmitted,
                        disabled: _vm.busy,
                      },
                      on: {
                        "update:value": function ($event) {
                          _vm.password = $event
                        },
                        "update:isValid": function ($event) {
                          _vm.passwordValid = $event
                        },
                        "update:is-valid": function ($event) {
                          _vm.passwordValid = $event
                        },
                      },
                    })
                  : _vm._e(),
                _vm.selectedFacility.name
                  ? [
                      _c("h2", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.coreString("facilityLabel")) +
                            "\n          "
                        ),
                      ]),
                      _c("p", { attrs: { "data-test": "facilityLabel" } }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.selectedFacility.name) +
                            "\n          "
                        ),
                      ]),
                    ]
                  : _vm._e(),
                _c("PrivacyLinkAndModal", {
                  staticClass: "privacy-link",
                  attrs: { modalProps: { hideOwnersSection: true } },
                }),
              ],
              2
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.atFirstStep,
                    expression: "!atFirstStep",
                  },
                ],
              },
              [
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$tr("demographicInfoOptional")) +
                      "\n        "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$tr("demographicInfoExplanation")) +
                      "\n        "
                  ),
                ]),
                _c(
                  "p",
                  [
                    _c("PrivacyLinkAndModal", {
                      staticClass: "privacy-link",
                      attrs: {
                        text: _vm.$tr("privacyLinkText"),
                        modalProps: { hideOwnersSection: true },
                      },
                    }),
                  ],
                  1
                ),
                _c("GenderSelect", {
                  staticClass: "select",
                  attrs: { value: _vm.gender, disabled: _vm.busy },
                  on: {
                    "update:value": function ($event) {
                      _vm.gender = $event
                    },
                  },
                }),
                _c("BirthYearSelect", {
                  staticClass: "select",
                  attrs: { value: _vm.birthYear, disabled: _vm.busy },
                  on: {
                    "update:value": function ($event) {
                      _vm.birthYear = $event
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "p",
              [
                _c("KButton", {
                  attrs: {
                    disabled: _vm.busy,
                    primary: true,
                    text: _vm.atFirstStep
                      ? _vm.coreString("continueAction")
                      : _vm.coreString("finishAction"),
                    type: "submit",
                  },
                }),
              ],
              1
            ),
            _c("KRouterLink", {
              attrs: {
                text: _vm.userString("signInPrompt"),
                to: _vm.$router.getRoute(_vm.ComponentMap.SIGN_IN),
                appearance: "basic-link",
              },
            }),
          ],
          1
        ),
      ]),
      _vm.atFirstStep
        ? _c(
            "div",
            { staticClass: "footer" },
            [_c("LanguageSwitcherFooter")],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }