var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "banner", style: { background: _vm.$themeTokens.surface } },
    [
      _c(
        "div",
        { staticClass: "banner-inner" },
        [
          _c(
            "KGrid",
            [
              _c(
                "KGridItem",
                {
                  attrs: {
                    layout8: { span: _vm.bannerClosed ? 5 : 8 },
                    layout12: { span: _vm.bannerClosed ? 9 : 12 },
                  },
                },
                [_vm._t("default", null, { bannerClosed: _vm.bannerClosed })],
                2
              ),
              _vm.bannerClosed
                ? _c(
                    "KGridItem",
                    {
                      staticClass: "button-grid-item",
                      attrs: { layout8: { span: 3 }, layout12: { span: 3 } },
                    },
                    [
                      _c("KButton", {
                        ref: "open_button",
                        staticClass: "open-button",
                        attrs: {
                          text: _vm.$tr("openButton"),
                          appearance: "flat-button",
                          primary: true,
                        },
                        on: { click: _vm.toggleBanner },
                      }),
                    ],
                    1
                  )
                : _c(
                    "KGridItem",
                    { staticClass: "button-grid-item" },
                    [
                      _c("KButton", {
                        ref: "close_button",
                        staticClass: "close-button",
                        attrs: {
                          text: _vm.coreString("closeAction"),
                          appearance: "flat-button",
                          primary: true,
                        },
                        on: { click: _vm.toggleBanner },
                      }),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }