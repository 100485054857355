var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sign-in-text" }, [
    _vm.showFacilityName && !_vm.showPasswordForm
      ? _c(
          "div",
          {
            staticStyle: {
              "margin-top": "24px",
              "margin-bottom": "16px",
              "text-align": "left",
            },
          },
          [
            _vm._v(
              "\n    " +
                _vm._s(
                  _vm.userString("signInToFacilityLabel", {
                    facility: _vm.selectedFacility.name,
                  })
                ) +
                "\n  "
            ),
          ]
        )
      : _vm.showFacilityName && _vm.showPasswordForm
      ? _c(
          "div",
          {
            staticStyle: {
              "margin-top": "24px",
              "margin-bottom": "16px",
              "text-align": "left",
            },
          },
          [
            _vm._v(
              "\n    " +
                _vm._s(
                  _vm.userString("signingInToFacilityAsUserLabel", {
                    facility: _vm.selectedFacility.name,
                    user: _vm.username,
                  })
                ) +
                "\n  "
            ),
          ]
        )
      : _vm.showPasswordForm
      ? _c("div", [
          _vm._v(
            "\n    " +
              _vm._s(
                _vm.userString("signingInAsUserLabel", { user: _vm.username })
              ) +
              "\n  "
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }