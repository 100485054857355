var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "mainWrapper",
      staticClass: "main-wrapper",
      style: _vm.mainWrapperStyles,
    },
    [
      !_vm.loading
        ? _c(
            "div",
            { staticClass: "scrolling-pane" },
            [
              _vm.coreBannerComponent && _vm.showDemoBanner
                ? _c("CoreBanner", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (props) {
                            return [
                              _c(_vm.coreBannerComponent, {
                                tag: "component",
                                attrs: { bannerClosed: props.bannerClosed },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4120537798
                    ),
                  })
                : _vm._e(),
              !_vm.isAuthorized
                ? _c("KPageContainer", [_c("AuthMessage")], 1)
                : _vm.error
                ? _c("KPageContainer", [_c("AppError")], 1)
                : _c(
                    "div",
                    {
                      staticClass: "main",
                      attrs: { id: "main", role: "main", tabindex: "-1" },
                    },
                    [_vm._t("default")],
                    2
                  ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { attrs: { "aria-live": "polite" } },
        [_c("GlobalSnackbar")],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }