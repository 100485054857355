var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AuthBase", { attrs: { busy: _vm.busy } }, [
    !_vm.needsToCreatePassword
      ? _c(
          "div",
          [
            _c(
              "div",
              {
                staticStyle: {
                  width: "100%",
                  "text-align": "left",
                  display: "block",
                },
              },
              [
                _vm.hasMultipleFacilities && !_vm.showPasswordForm
                  ? _c("KRouterLink", {
                      staticStyle: {
                        "margin-top": "24px",
                        "margin-left": "-4px",
                      },
                      attrs: {
                        icon: "back",
                        text: _vm.coreString("changeLearningFacility"),
                        to: _vm.backToFacilitySelectionRoute,
                      },
                    })
                  : _vm._e(),
                _vm.showPasswordForm
                  ? _c("KButton", {
                      staticStyle: {
                        "margin-top": "24px",
                        "margin-left": "4px",
                      },
                      attrs: {
                        appearance: "basic-link",
                        text: _vm.$tr("changeUser"),
                      },
                      on: { click: _vm.clearUser },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "icon",
                            fn: function () {
                              return [
                                _c("KIcon", {
                                  staticStyle: {
                                    width: "24px",
                                    height: "24px",
                                    top: "6px",
                                    right: "8px",
                                  },
                                  attrs: {
                                    icon: "back",
                                    color: _vm.$themeTokens.primary,
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3791659636
                      ),
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c("SignInHeading", {
              attrs: {
                showFacilityName: _vm.showFacilityName,
                showPasswordForm: _vm.showPasswordForm,
                username: _vm.username,
              },
            }),
            _c(
              "form",
              {
                ref: "form",
                staticClass: "login-form",
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.signIn.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showUsernameForm,
                        expression: "showUsernameForm",
                      },
                    ],
                  },
                  [
                    _c(
                      "transition",
                      { attrs: { name: "textbox" } },
                      [
                        _c("KTextbox", {
                          ref: "username",
                          attrs: {
                            id: "username",
                            autocomplete: "username",
                            autofocus: true,
                            label: _vm.coreString("usernameLabel"),
                            invalid: _vm.usernameIsInvalid,
                            invalidText: _vm.usernameIsInvalidText,
                          },
                          on: {
                            blur: _vm.handleUsernameBlur,
                            input: _vm.handleUsernameInput,
                            keydown: _vm.handleUsernameKeydown,
                          },
                          model: {
                            value: _vm.username,
                            callback: function ($$v) {
                              _vm.username =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "username",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("transition", { attrs: { name: "list" } }, [
                      _c("div", { staticClass: "suggestions-wrapper" }, [
                        _vm.simpleSignIn && _vm.suggestions.length
                          ? _c(
                              "ul",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.showDropdown,
                                    expression: "showDropdown",
                                  },
                                ],
                                staticClass: "suggestions",
                                style: {
                                  backgroundColor: _vm.$themeTokens.surface,
                                },
                              },
                              _vm._l(_vm.suggestions, function (suggestion, i) {
                                return _c("UiAutocompleteSuggestion", {
                                  key: i,
                                  style: _vm.suggestionStyle(i),
                                  attrs: { suggestion: suggestion },
                                  nativeOn: {
                                    mousedown: function ($event) {
                                      return _vm.fillUsername(suggestion)
                                    },
                                  },
                                })
                              }),
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _c(
                      "div",
                      [
                        _c("KButton", {
                          staticClass: "login-btn",
                          attrs: {
                            text: _vm.$tr("nextLabel"),
                            primary: true,
                            disabled: !_vm.isNextButtonEnabled,
                          },
                          on: { click: _vm.signIn },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.showPasswordForm
                  ? _c(
                      "div",
                      [
                        _vm.invalidCredentials
                          ? _c(
                              "UiAlert",
                              { attrs: { type: "error", dismissible: false } },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.$tr("incorrectPasswordError")) +
                                    "\n        "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "transition",
                          { attrs: { name: "textbox" } },
                          [
                            _c("KTextbox", {
                              ref: "password",
                              attrs: {
                                id: "password",
                                type: "password",
                                autocomplete: "current-password",
                                label: _vm.coreString("passwordLabel"),
                                autofocus: true,
                                invalid: _vm.passwordIsInvalid,
                                invalidText: _vm.passwordIsInvalidText,
                                floatingLabel: false,
                              },
                              on: { blur: _vm.handlePasswordBlur },
                              model: {
                                value: _vm.password,
                                callback: function ($$v) {
                                  _vm.password = $$v
                                },
                                expression: "password",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("KButton", {
                              staticClass: "login-btn",
                              attrs: {
                                type: "submit",
                                text: _vm.coreString("signInLabel"),
                                primary: true,
                                disabled: _vm.busy,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
            _vm.showUsersList && !_vm.showPasswordForm
              ? _c("UsersList", {
                  attrs: {
                    users: _vm.usernamesForCurrentFacility,
                    busy: _vm.busy,
                  },
                  on: { userSelected: _vm.setSelectedUsername },
                })
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }